import { RichTextFormatter } from '@/core/localization/translate';
import { translate, translateFormatter } from '@/domains/core/localization/translate';
import { footerLegalConsumptionInformationHrefB2B as realFooterLegalConsumptionInformationHrefB2B, footerLegalMentionsHrefB2B as realFooterLegalMentionsHrefB2B, footerLegalProtectionHref as realFooterLegalProtectionHref, footerLegalTermsHrefB2B as realFooterLegalTermsHrefB2B } from './modules/LegalData/translations';
export const footerLegalConsumptionInformationHrefB2B = realFooterLegalConsumptionInformationHrefB2B;
export const footerLegalMentionsHrefB2B = realFooterLegalMentionsHrefB2B;
export const footerLegalProtectionHref = realFooterLegalProtectionHref;
export const footerLegalTermsHrefB2B = realFooterLegalTermsHrefB2B;

// Contact Newsletter Footer/Modal
export const footerContactNewsletterCommon = {
  ...translate({
    "inputLabel": "Direcci\xF3n de email"
  }),
  legal: undefined
};
export const footerContactNewsletterModal = translate({
  "title": "\xBFQuiere recibir (buenos) consejos y s\xFAper ofertas?",
  "subtitle": "Suscr\xEDbete a la newsletter de ManoMano.",
  "subtext": "Solo utilizaremos tu email para enviarte nuestras newsletters (ofertas, promociones, etc.). Puedes desinscribirte en cualquier momento utilizando el enlace que hay en la parte inferior de la newsletter.",
  "subtextLink": "M\xE1s informaci\xF3n sobre la gesti\xF3n de mis datos."
});
export const footerContactNewsletter = translate({
  "title": "\xBFQuiere recibir (buenos) consejos y s\xFAper ofertas?",
  "subtitle": "Suscr\xEDbete a la newsletter de ManoMano.",
  "subtextLink": "MM\xE1s informaci\xF3n"
});
export const footerContactNewsletterIncentiveVariantModal = translate({
  "title": "\xA15\u20AC* para ti, (buenos) consejos y ofertas especiales!",
  "subtitle": "Suscr\xEDbase a nuestro bolet\xEDn",
  "subtextLink": "M\xE1s informaci\xF3n sobre la gesti\xF3n de datos y sus derechos.",
  "subtext": "Su direcci\xF3n de correo electr\xF3nico s\xF3lo se utilizar\xE1 para enviarle nuestros boletines. Puede darse de baja a trav\xE9s del enlace que figura en el bolet\xEDn.",
  "legal": "*C\xF3digo promocional enviado por email tras la suscripci\xF3n a la newsletter, v\xE1lido para un pedido con una cesta de la compra m\xEDnima de 150 euros y durante 1 mes desde su recepci\xF3n."
});
export const footerContactNewsletterIncentiveVariant = translate({
  "title": "Suscr\xEDbete a nuestra newsletter y consigue 5\u20AC de descuento*,  adem\xE1s de consejos y ofertas exclusivas",
  "subtitle": "*C\xF3digo promocional enviado por email tras la suscripci\xF3n, v\xE1lido para una compra m\xEDnima de 150\u20AC y durante 1 mes a partir de su recepci\xF3n.",
  "subtextLink": "M\xE1s informaci\xF3n"
});
export const footerContactSubtextIncentiveVariant = translateFormatter<{
  br: RichTextFormatter;
}>("Usaremos tu email exclusivamente para enviarte nuestras newsletters. <br></br>En cualquier momento podr\xE1s darte de baja haciendo clic en el enlace de la newsletter.", undefined, undefined, "es");
export const footerContactSubtext = translateFormatter<{
  br: RichTextFormatter;
}>("Usaremos tu email exclusivamente para enviarte nuestras newsletters. <br></br>En cualquier momento podr\xE1s darte de baja haciendo clic en el enlace de la newsletter.", undefined, undefined, "es");
// Contact Newsletter Success Modal
export const contactNewsletterModalSuccess = translate({
  "title": "\xA1Felicidades!",
  "subtitle": "\xA1Aprovecha nuestras promociones exclusivas para que realices tus proyectos de bricolaje sin gastar mucho!"
});
export const contactNewsletterModalSuccessIncentiveVariant = translate({
  "title": "\xA1Felicidades!",
  "subtitle": "\xA1Es tu turno para conseguir las mejores ofertas y promociones! Podr\xE1s juguetear con tu cartera tranquilamente"
});
export const {
  availablePlayStore,
  detailsApp,
  detailsProApp,
  downloadAppStore,
  footerAboutSubTitle,
  footerAboutTitle,
  footerAppStoreMamomanoProUrl,
  footerAppStoreMamomanoUrl,
  footerB2cConnectProLink,
  footerBestSellers,
  footerBestSellersHref,
  footerContactCountrySubTitle,
  footerContactCountryTitle,
  footerContactNewsletterInvalidError,
  footerContactNewsletterSubmitText,
  footerContactServiceCallPrice,
  footerContactServiceSubTitle,
  footerContactServiceTitle,
  footerContactSocialNetworkSubTitle,
  footerContactSocialNetworkTitle,
  footerContactSubTitle,
  footerContactTitle,
  footerManoExpress,
  footerManoExpressHref,
  footerManoExpressHrefB2B,
  footerNavigationAbout,
  footerNavigationAboutHref,
  footerNavigationAffiliation,
  footerNavigationAffiliationHref,
  footerNavigationBrands,
  footerNavigationBrandsHref,
  footerNavigationContactUs,
  footerNavigationContactUsHref,
  footerNavigationCouponLegalMention,
  footerNavigationCouponLegalMentionHref,
  footerNavigationHelp,
  footerNavigationHelpHref,
  footerNavigationOrders,
  footerNavigationOrdersHref,
  footerNavigationPressContact,
  footerNavigationPressContactHref,
  footerNavigationRecruitingSellers,
  footerNavigationRecruitingSellersHref,
  footerNavigationRecruitment,
  footerNavigationRecruitmentHref,
  footerPlaymarketMamomanoProUrl,
  footerPlaymarketMamomanoUrl,
  footerProButtonTitle,
  footerQualityPaymentTitle,
  footerReportContentDSALabel,
  footerReportContentDSALink,
  footerSitemapAdvice,
  footerSitemapAdviceHref,
  footerSitemapCurrentOffers,
  footerSitemapCurrentOffersHref,
  footerSitemapProductCategories,
  footerSitemapProductCategoriesHref,
  footerSitemapInspirations,
  footerSitemapInspirationsHref,
  footerSitemapTopProducts,
  footerSitemapTopProductsHref,
  footerToTopButtonAriaLabel,
  footerTrustProSubtitle,
  footerTrustProTitle,
  titleApp,
  titleProApp
} = translate({
  "availablePlayStore": "Disponible en Google Play",
  "detailsApp": "M\xE1s de 6 millones de productos en tu bolsillo",
  "detailsProApp": "* Nuestra aplicaci\xF3n para profesionales",
  "downloadAppStore": "Consiguelo en el App Store",
  "footerAboutSubTitle": "Ahora toca ponerse serios",
  "footerAboutTitle": "Qui\xE9nes somos",
  "footerAppStoreMamomanoUrl": "https://apps.apple.com/es/app/manomano/id1503142603",
  "footerAppStoreMamomanoProUrl": "https://apps.apple.com/es/app/manomano-pro/id1498180724",
  "footerB2cConnectProLink": "/identificarse?b2b=true",
  "footerBestSellers": "Top ventas",
  "footerBestSellersHref": "/bs",
  "footerContactCountrySubTitle": "Pr\xF3ximamente en Marte...",
  "footerContactCountryTitle": "Internacionales",
  "footerContactNewsletterInvalidError": "El correo electr\xF3nico indicado no es correcto.",
  "footerContactNewsletterSubmitText": "Registrarse",
  "footerContactServiceCallPrice": "Precio de una llamada local.",
  "footerContactServiceSubTitle": "\xA1ManoMano, a tu servicio!",
  "footerContactServiceTitle": "Servicio al cliente",
  "footerContactSocialNetworkSubTitle": "La magia de Internet en todo el mundo.",
  "footerContactSocialNetworkTitle": "\xBFConoces nuestras redes sociales?",
  "footerContactSubTitle": "Fax (si todavia lo sigues usando)",
  "footerContactTitle": "Informaci\xF3n y contacto",
  "footerNavigationAbout": "\xBFQui\xE9nes somos?",
  "footerNavigationAboutHref": "https://about.manomano.com/es",
  "footerNavigationAffiliation": "Programa de Afiliaci\xF3n",
  "footerNavigationAffiliationHref": "https://info.manomano.com/unete-a-nuestro-programa/",
  "footerNavigationBrands": "Nuestras marcas",
  "footerNavigationBrandsHref": "/marcas",
  "footerNavigationContactUs": "Contacta con nosotros",
  "footerNavigationContactUsHref": "/contacto",
  "footerNavigationCouponLegalMention": "Condiciones generales de uso de los cupones",
  "footerNavigationCouponLegalMentionHref": "/lp/codigos-promocionales-manomano-ahorra-en-tus-compras-con-nuestras-ofertas-exclusivas-732",
  "footerNavigationHelp": "\xBFNecesitas ayuda?",
  "footerNavigationHelpHref": "/ayuda",
  "footerNavigationOrders": "Seguimiento de pedido",
  "footerNavigationOrdersHref": "/pedidos",
  "footerNavigationPressContact": "Prensa",
  "footerNavigationPressContactHref": "https://about.manomano.com/es/noticias/",
  "footerNavigationRecruitingSellers": "Hacerse vendedor",
  "footerNavigationRecruitingSellersHref": "/lp/vender-en-manomano-2654",
  "footerNavigationRecruitment": "Buscamos compa\xF1eros",
  "footerNavigationRecruitmentHref": "https://careers.manomano.jobs/en",
  "footerPlaymarketMamomanoProUrl": "https://play.google.com/store/apps/details?id=com.manomano.pro&hl=es",
  "footerPlaymarketMamomanoUrl": "https://play.google.com/store/apps/details?id=com.manomano&hl=es",
  "footerProButtonTitle": "Acceder a tu cuenta Pro",
  "footerQualityPaymentTitle": "Pago seguro",
  "footerReportContentDSALabel": "Reportar un contenido il\xEDcito",
  "footerReportContentDSALink": "https://survey.zohopublic.eu/zs/Y2DHs5?lang=es",
  "footerSitemapAdvice": " Nuestro consejo",
  "footerSitemapAdviceHref": "/nuestro-consejo",
  "footerSitemapCurrentOffers": "Nuestras ofertas actuales",
  "footerSitemapInspirations": "Nuestras inspiraciones",
  "footerSitemapInspirationsHref": "/consejos/nuestras-inspiraciones-14914",
  "footerSitemapCurrentOffersHref": "/nuestras-ofertas-actuales",
  "footerSitemapProductCategories": "Las categor\xEDas de productos m\xE1s populares",
  "footerSitemapProductCategoriesHref": "/las-categorias-de-productos-mas-populares",
  "footerSitemapTopProducts": "Nuestras comparativas",
  "footerSitemapTopProductsHref": "/nuestras-comparativas",
  "footerManoExpress": "Descubrir ManoExpress",
  "footerManoExpressHref": "/lp/manoexpress-3126",
  "footerManoExpressHrefB2B": "/lp/manoexpress-3127",
  "footerToTopButtonAriaLabel": "Volver a subir",
  "footerTrustProSubtitle": "Las mejores marcas a precios Pros!",
  "footerTrustProTitle": "\xBFEres un profesional?",
  "titleApp": "La aplicaci\xF3n ManoMano",
  "titleProApp": "Descubre la app ManoManoPro"
});